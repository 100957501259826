import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function WhitePaperPage() {
  const hrefClassName = "text-green-500 hover:text-green-600"
  return (
    <Layout>
      <Seo title="How we determine sustainability" />
      <div className="relative overflow-hidden bg-white py-16">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
          <div
            className="relative mx-auto h-full max-w-prose text-lg"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h1>
              <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                How we determine sustainability
              </span>
            </h1>
            <p className="mt-8 text-center text-xl text-black">
              Here is the research that underpins your Sustainable Renovation
              Report:
            </p>
            <ul className="list-disc">
              <li>
                <b>Our benefit categories</b>: There are a number of
                globally-recognised sustainability rating tools that all use a
                multi-factor approach to sustainability, we have used a category
                system that is similar to the areas addressed by these
                benchmarking systems. You can learn more about why energy,
                water, healthy materials, social benefit, carbon emissions and
                waste are all considered important{" "}
                <a
                  className={hrefClassName}
                  href="https://www.wbdg.org/resources/green-building-standards-and-certification-systems"
                  target="_blank"
                >
                  here
                </a>
                . We also added a category for affordability, because this is so
                important for most Australian households.
              </li>
              <li>
                <b>Percentages for the benefit categories</b>: The CRC for Low
                Carbon Living produced a number of expert guides that explain
                how to save energy and reduce emissions through{" "}
                <a
                  className={hrefClassName}
                  href="http://www.lowcarbonlivingcrc.com.au/sites/all/files/publications_file_attachments/lclguide_households_web.pdf"
                  target="_blank"
                >
                  how you operate your home
                </a>{" "}
                (including the appliances you choose) and suggestions for{" "}
                <a
                  className={hrefClassName}
                  href="http://www.lowcarbonlivingcrc.com.au/sites/all/files/publications_file_attachments/lclguide_residential_retrofit_web.pdf"
                  target="_blank"
                >
                  renovations that will reduce energy use and emissions
                </a>{" "}
                and make a home more comfortable. We also factored in research
                about the{" "}
                <a
                  className={hrefClassName}
                  href="http://publicationslist.org/data/meng.chong/ref-36/UWSRA-tr49.pdf"
                  target="_blank"
                >
                  value of water-efficiency
                </a>
                ,{" "}
                <a
                  className={hrefClassName}
                  href="https://theconversation.com/urban-greening-can-save-species-cool-warming-cities-and-make-us-happy-116000"
                  target="_blank"
                >
                  biodiversity and urban greening
                </a>{" "}
                and the importance of social benefit, and then adjusted the
                percentages to reflect the{" "}
                <a
                  className={hrefClassName}
                  href="https://ahd.csiro.au/dashboards/energy-rating/ncc-climates/"
                  target="_blank"
                >
                  CSIRO’s Climate Zone
                </a>{" "}
                considerations as outlined in the National Construction Code.
                Shade, for example, is far more beneficial in an Adelaide summer
                than it is in an Alpine zone summer; and natural ventilation is
                essential in a humid Tropical zone, but thick wall insulation,
                not so much.
              </li>
              <li>
                <b>Energy Consumption</b>: The Australian Energy Regulator
                publishes an annual report on household average energy
                consumption of both electricity and gas for each climate zone.
                We used{" "}
                <a
                  className={hrefClassName}
                  href="https://www.aer.gov.au/system/files/Residential%20energy%20consumption%20benchmarks%20-%209%20December%202020_0.pdf"
                  target="_blank"
                >
                  the 2020 report
                </a>
                . We also consulted the{" "}
                <a
                  className={hrefClassName}
                  href="https://renew.org.au/resources/how-we-can-help/efficient-electric-homes/how-we-can-help-going-off-gas/"
                  target="_blank"
                >
                  excellent research by Renew
                </a>{" "}
                (formerly the ATA) on the relative emissions and costs of
                all-electric homes compared to homes with gas for heating,
                cooking and/or hot water.

                Calculations for carbon emissions (operational) are based on the data and factors provided by the Department of Climate change, energy, the environment and water - 
                <a
                  className={hrefClassName}
                  href="https://www.dcceew.gov.au/climate-change/publications/national-greenhouse-accounts-factors-2022"
                  target="_blank"
                >
                  2022 Australian National Greenhouse Accounts Factors
                </a>.

              </li>
              <li>
                <b>Insulation</b>: according to the{" "}
                <a
                  className={hrefClassName}
                  href="https://www.abs.gov.au/AUSSTATS/abs@.nsf/Lookup/4614.0.55.002Main+Features4Apr+2010"
                  target="_blank"
                >
                  Australia Bureau of Statistics
                </a>
                , wall and ceiling insulation can reduce energy use for heating
                and cooling by up to 45%
              </li>
              <li>
                <b>Solar PV</b>: assuming the alternative is grid energy from
                non-renewable sources, each kilowatt of installed solar PV used
                instead of grid energy{" "}
                <a
                  className={hrefClassName}
                  href="https://theconversation.com/how-to-neutralise-your-greenhouse-gas-footprint-103922"
                  target="_blank"
                >
                  saves about 1.2 tonnes of CO2 per year
                </a>
                . The average household system ranges from 5kW to 15 kW.
              </li>
              <li>
                <b>Hot water systems</b>: the average household using a
                conventional gas or electric off-peak hot water system powered
                by mains electricity can generate around six tonnes of CO2
                emissions a year, according to the{" "}
                <a
                  className={hrefClassName}
                  href="https://apps.epa.vic.gov.au/AGC/r_emissions.html#page-18/!"
                  target="_blank"
                >
                  Australian Greenhouse Calculator
                </a>
                . Solar hot water or highly-efficient electric heat pumps reduce
                this by at least half, if not more.
              </li>
              <li>
                <b>Embodied carbon</b>: This is a{" "}
                <a
                  className={hrefClassName}
                  href="https://gresb.com/nl-en/material-considerations-for-reducing-building-related-carbon-footprint/"
                  target="_blank"
                >
                  complex category
                </a>{" "}
                as it includes the emissions generated manufacturing a material
                and the emissions generated transporting the material including
                all energy and fuel for extraction, processing, packaging,
                transporting, the store and warehouse stages and then the final
                trip to the site! You can read more about some of the research
                being done into calculating embodied carbon for new materials{" "}
                <a
                  className={hrefClassName}
                  href="https://www.level.org.nz/material-use/embodied-energy/"
                  target="_blank"
                >
                  here
                </a>
                . What we do know from{" "}
                <a
                  className={hrefClassName}
                  href="https://www.gensler.com/climate-action-2021-the-adaptive-reuse-revolution"
                  target="_blank"
                >
                  international researchers
                </a>{" "}
                is that re-using the materials already in your home translates
                into an embodied carbon saving of at least 40% - and may be much
                more.{" "}
              </li>
              <li>
                <b>Low-allergy materials</b>: Our definition of low-allergy
                materials and healthy materials is based on the{" "}
                <a
                  className={hrefClassName}
                  href="https://living-future.org/declare/declare-about/red-list/"
                  target="_blank"
                >
                  Living Future Institute’s Red List
                </a>{" "}
                of materials, chemicals and compounds known to have negative
                impacts on our health and wellbeing.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}
